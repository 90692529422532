import React from 'react'

import * as Styled from './styles/Heading.styles'

const Heading = ({ heading, subheading }) => (
  <Styled.Container>
    <Styled.Heading>{heading}</Styled.Heading>
    <Styled.Subheading>{subheading}</Styled.Subheading>
  </Styled.Container>
)

export default Heading
