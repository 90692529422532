import styled from 'styled-components'

import { DEVICE } from '../../../util/constants'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 32px repeat(10, 1fr) 32px;
  padding-bottom: 30px;

  @media ${DEVICE.tabletL} {
    grid-template-columns: 1fr repeat(10, 1fr) 1fr;
    max-width: 1317px;
    padding-bottom: 50px;
  }
`

export const Row = styled.div`
  display: grid;
  grid-column: 2 / 12;
  row-gap: 50px;

  @media ${DEVICE.tabletL} {
    column-gap: 34px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${DEVICE.laptopS} {
    grid-template-columns: repeat(3, 1fr);
  }
`
