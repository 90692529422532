import React from 'react'
import { graphql } from 'gatsby'

import { Routes, routeWithParams } from '../util/routes'

import SEO from '../components/SEO'
import Layout from '../components/Layout'
import ResponsiveGrid from '../components/ContentBlocks/ResponsiveGrid'
import Heading from '../components/ContentBlocks/Heading'
import LeadershipBlock from '../components/ContentBlocks/LeadershipBlock'
import SliceRenderer from '../components/ContentBlocks/SliceRenderer'

const LeadershipLandingPage = ({ data }) => {
  const page = data.prismicStaffBoardLandingPage
  if (!page) {
    return null
  }
  const {
    body,
    facebook_image,
    members,
    meta_description,
    meta_keywords,
    seo_title,
    seo_embed_code,
    title,
    body_copy,
    twitter_image,
  } = page.data

  return (
    <>
      <SEO
        seo={{
          description: meta_description,
          keywords: meta_keywords,
          title: title?.text ? title.text : null,
          twitter_image: twitter_image?.url ? twitter_image : null,
          facebook_image: facebook_image?.url ? facebook_image : null,
          seo_embed_code: seo_embed_code?.text ?? null,
        }}
      />
      <Layout>
        <Heading
          heading={title?.text ?? ''}
          subheading={body_copy?.text ?? ''}
        />
        {/* <pre>{JSON.stringify(members, false, 2)}</pre> */}
        <ResponsiveGrid
          renderItems={
            !!members &&
            members.map(member => {
              return (
                // <pre>{JSON.stringify(member, false, 2)}</pre>
                <LeadershipBlock
                  key={member.member?.document?.id}
                  image={
                    member.member?.document?.data.author_image?.thumbnails
                      ?.executive_team_desktop
                  }
                  bio={member.member?.document?.data?.bio_summary?.text}
                  name={member.member?.document?.data?.name?.text}
                  title={member.member?.document?.data?.title?.text}
                  detailPageUrl={routeWithParams(Routes.STAFF_DETAIL, {
                    uid: member.member?.document?.uid,
                  })}
                />
              )
            })
          }
        />
        {!!body &&
          body.map(block => <SliceRenderer key={block.id} block={block} />)}
      </Layout>
    </>
  )
}

export const query = graphql`
  query LeadershipLandingQuery($id: String) {
    prismicStaffBoardLandingPage(id: { eq: $id }) {
      id
      uid
      data {
        is_board_members_page
        title {
          text
          richText
        }
        body_copy {
          richText
        }
        seo_embed_code {
          text
        }
        members {
          member {
            document {
              ... on PrismicNpfAuthor {
                id
                uid
                data {
                  author_image {
                    url
                    alt
                    thumbnails {
                      executive_team_desktop {
                        url
                      }
                    }
                  }
                  bio_summary {
                    text
                    richText
                  }
                  name {
                    text
                  }
                  title {
                    text
                  }
                }
              }
            }
          }
        }
        body {
          ... on PrismicStaffBoardLandingPageDataBodyAccordionBlock {
            id
            slice_type
            primary {
              section_header {
                text
              }
              bottom_padding_adjustment
            }
            items {
              content {
                richText
              }
              block_header {
                text
              }
            }
          }
          ... on PrismicStaffBoardLandingPageDataBodyAuthorBlock {
            id
            slice_type
            primary {
              author {
                document {
                  ... on PrismicGuestAuthor {
                    id
                    type
                    data {
                      name {
                        text
                      }
                      title {
                        text
                      }
                      author_image {
                        alt
                        url
                      }
                    }
                  }
                  ... on PrismicNpfAuthor {
                    id
                    type
                    uid
                    data {
                      name {
                        text
                      }
                      title {
                        text
                      }
                      bio {
                        richText
                      }
                      author_image {
                        alt
                        url
                      }
                    }
                  }
                }
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicStaffBoardLandingPageDataBodyBasicImageBlock {
            id
            slice_type
            primary {
              caption {
                text
              }
              image_link {
                url
                uid
                type
                link_type
                id
              }
              image {
                alt
                url
                thumbnails {
                  mobile {
                    url
                  }
                }
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicStaffBoardLandingPageDataBodyBodyContent {
            id
            slice_type
            primary {
              body_copy {
                richText
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicStaffBoardLandingPageDataBodyCalendarOfEventsBlock {
            id
            slice_type
            primary {
              bottom_padding_adjustment
            }
          }
          ... on PrismicStaffBoardLandingPageDataBodyCtaBlock {
            id
            slice_type
            primary {
              cta_link_text
              cta_link {
                id
                url
                uid
                type
                link_type
              }
              body_text {
                richText
              }
              header {
                text
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicStaffBoardLandingPageDataBodyCustomForm {
            id
            slice_type
            primary {
              form_title
              form_embed_code {
                text
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicStaffBoardLandingPageDataBodyEventBlock {
            id
            slice_type
            primary {
              bottom_padding_adjustment
            }
            items {
              event {
                id
                document {
                  ... on PrismicEvent {
                    data {
                      end_date
                      event_date
                      event_city
                      event_state
                      end_time
                      start_time
                      event_venue
                      event_address_line_1
                      event_address_line_2
                      event_image {
                        alt
                        url
                        thumbnails {
                          mobile {
                            url
                          }
                        }
                      }
                      event_title {
                        text
                      }
                      event_description {
                        text
                      }
                      event_link {
                        id
                        link_type
                        uid
                        url
                        type
                      }
                      event_link_text
                    }
                  }
                }
              }
            }
          }
          ... on PrismicStaffBoardLandingPageDataBodyGoogleAd {
            id
            slice_type
            primary {
              google_ad_tag {
                document {
                  ... on PrismicGoogleAdTag {
                    id
                  }
                }
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicStaffBoardLandingPageDataBodyGridBlock {
            id
            slice_type
            primary {
              layout_type
              bottom_padding_adjustment
            }
            items {
              cta_link_text
              cta_link {
                url
                uid
                type
                link_type
                id
              }
              image {
                alt
                url
                thumbnails {
                  mobile {
                    url
                  }
                }
              }
              summary {
                richText
              }
              title {
                text
              }
            }
          }
          ... on PrismicStaffBoardLandingPageDataBodyIconTextBlock {
            id
            slice_type
            primary {
              header {
                richText
              }
              body_text {
                richText
              }
              bottom_padding_adjustment
            }
            items {
              cta_text
              item_title {
                richText
              }
              item_icon {
                alt
                url
                thumbnails {
                  mobile {
                    url
                  }
                }
              }
              item_body_text {
                richText
              }
              cta_link {
                id
                url
                uid
                type
                link_type
              }
            }
          }
          ... on PrismicStaffBoardLandingPageDataBodyImageBlockWithText {
            id
            slice_type
            primary {
              layout_option
              text_content {
                richText
              }
              cta_text {
                text
              }
              cta_link {
                uid
                link_type
                id
                type
                url
              }
              header_text {
                text
              }
              image {
                alt
                url
                thumbnails {
                  mobile {
                    url
                  }
                }
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicStaffBoardLandingPageDataBodySponsorsBlock {
            id
            slice_type
            items {
              sponsor_logo {
                alt
                url
              }
              sponsor_link {
                url
                uid
                link_type
                id
              }
            }
            primary {
              intro_text {
                richText
              }
              header {
                text
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicStaffBoardLandingPageDataBodyStatisticsBlock {
            id
            slice_type
            primary {
              citation
              header {
                text
              }
              subhead {
                text
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicStaffBoardLandingPageDataBodyVideoBlock {
            id
            slice_type
            primary {
              caption {
                text
              }
              schema_publish_date
              schema_video_description
              video_duration_hours
              video_duration_minutes
              video_duration_seconds
              transcription_link {
                url
                uid
                type
                link_type
              }
              video_title {
                text
              }
              video_link {
                embed_url
              }
              bitmovin_stream_id
              bottom_padding_adjustment
            }
          }
        }
      }
    }
  }
`

export default LeadershipLandingPage
