import styled from 'styled-components'
import { DEVICE } from '../../../util/constants'

export const Link = styled.a`
  display: flex;
  flex-direction: column;
  text-decoration: none;
`

export const Image = styled.img`
  display: block;
  height: auto;
  margin-bottom: 15px;
  width: 100%;
`

export const Name = styled.h2`
  color: var(--content-background-dark-blue);
  display: block;
  font-size: 2rem;
  line-height: 1.375;
  margin-bottom: 10px;

  a:hover & {
    text-decoration: underline;
  }

  @media ${DEVICE.tabletL} {
    font-size: 2.4rem;
  }
`

export const Title = styled.h3`
  color: var(--highlight-blue);
  display: block;
  font-size: 1.6rem;
  line-height: 1.375;
  margin-bottom: 10px;

  @media ${DEVICE.tabletL} {
    font-size: 1.8rem;
  }
`

export const Bio = styled.p`
  color: var(--content-background-dark-blue);
  display: block;
  font-size: 1.4rem;
  line-height: 1.375;
`
