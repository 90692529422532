import React from 'react'

import * as Styled from './styles/ResponsiveGrid.styles'

const ResponsiveGrid = ({ renderItems }) =>
  <Styled.Container>
    <Styled.Row>
      {renderItems}
    </Styled.Row>
  </Styled.Container>

export default ResponsiveGrid