import React from 'react'

import * as Styled from './styles/LeadershipBlock.styles'

const LeadershipBlock = ({ bio, detailPageUrl, image, name, title }) => (
  <Styled.Link href={detailPageUrl}>
    {!!image?.url && <Styled.Image src={image.url} alt={image.alt} />}
    <Styled.Name>{name}</Styled.Name>
    {!!title && <Styled.Title>{title}</Styled.Title>}
    <Styled.Bio>{bio}</Styled.Bio>
  </Styled.Link>
)

export default LeadershipBlock
